import React from 'react'
import { PrismicRichText } from '@prismicio/react'
import { object, bool, string } from 'prop-types'

import * as Styled from './styles/MediaLibraryItem.styles'
import {
  MediaIcon,
  getMetadataDateByType,
  getCtaLinkTextByType,
} from './MediaLibraryCommon'
import { DISPLAY_WIDTH } from '../../util/constants'
import { formatDate } from '../../util/timeDateUtils'
import { CtaContainer, TextLink } from './styles/CtaLink.styles'
import { ArrowInCircle } from '../Icons'
import LinkWrapper from './LinkWrapper'

import MOBILE_DEFAULT_HIGHLIGHT_IMAGE from '../../images/logo-over-blue-311x186.png'
import DESKTOP_DEFAULT_HIGHLIGHT_IMAGE from '../../images/logo-over-blue-600x360.png'

const HighlightMediaItem = ({ item }) => {
  const doc = item.document.data
  const mobileHero = doc.hero_image?.thumbnails?.library_hero_mobile
    ? doc.hero_image.thumbnails.library_hero_mobile.url
    : MOBILE_DEFAULT_HIGHLIGHT_IMAGE

  const desktopHero = doc.hero_image?.thumbnails?.library_hero_desktop
    ? doc.hero_image.thumbnails.library_hero_desktop.url
    : DESKTOP_DEFAULT_HIGHLIGHT_IMAGE

  return (
    <Styled.HighlightRegion>
      <LinkWrapper link={item.document}>
        <Styled.HighlightMediaItemImage>
          <source
            media={`(max-width: ${DISPLAY_WIDTH.tabletL - 1}px)`}
            srcSet={mobileHero}
          />
          <source
            media={`(min-width: ${DISPLAY_WIDTH.tabletL}px)`}
            srcSet={desktopHero}
          />
          <img
            src={desktopHero}
            alt={
              doc.hero_image?.alt ? doc.hero_image.alt : 'Featured Media Item'
            }
          />
        </Styled.HighlightMediaItemImage>
      </LinkWrapper>
      <Styled.HighlightInfoColumn to={`${item.uid}`}>
        <Styled.MediaItemMetadataRow>
          <MediaIcon type={doc.media_type.document.data.type} />
          <Styled.MetadataTextRegion>
            <Styled.MetadataTitle>
              {doc.topic?.document?.data?.topic ?? ''}
            </Styled.MetadataTitle>
            <Styled.MetadataDate>
              {getMetadataDateByType(doc)}
            </Styled.MetadataDate>
          </Styled.MetadataTextRegion>
        </Styled.MediaItemMetadataRow>
        <Styled.HighlightMediaTitle>
          {doc.title.text}
        </Styled.HighlightMediaTitle>
        <CtaContainer as="div">
          <TextLink>
            {getCtaLinkTextByType(doc.media_type.document.data.type)}
          </TextLink>
          <ArrowInCircle />
        </CtaContainer>
      </Styled.HighlightInfoColumn>
    </Styled.HighlightRegion>
  )
}

const ListViewMediaItem = ({ item, uid, mediaFilter }) => (
  <>
    {(!mediaFilter || mediaFilter === item.media_type.document.data.type) && (
      // <pre>{JSON.stringify(item, false, 2)}</pre>
      <Styled.MediaItemColumn to={`/watch-and-listen/${uid}/`}>
        <Styled.MediaItemIconImageRow>
          <MediaIcon type={item.media_type.document.data.type} />
          {item.hero_image?.thumbnails?.library_thumbnail?.url && (
            <img
              src={item.hero_image.thumbnails.library_thumbnail.url}
              alt={item.hero_image.alt}
            />
          )}
        </Styled.MediaItemIconImageRow>
        <Styled.MediaItemTopic>
          {item.topic?.document?.data?.topic ?? ''}
          {item.show_cme_tag && <span>&nbsp;*CME</span>}
        </Styled.MediaItemTopic>
        <Styled.MediaItemDate>
          {formatDate(item.datestamp)}
        </Styled.MediaItemDate>
        <Styled.MediaItemTitle>
          {item.title && <PrismicRichText field={item.title.richText} />}
        </Styled.MediaItemTitle>
        <CtaContainer as="div">
          <TextLink>
            {getCtaLinkTextByType(item.media_type.document.data.type)}
          </TextLink>
          <ArrowInCircle />
        </CtaContainer>
      </Styled.MediaItemColumn>
    )}
  </>
)

const MediaLibraryItem = ({
  item,
  isHighlightMediaItem = false,
  mediaFilter = null,
  topicFilter = null,
}) => (
  <>
    {/* <pre>{JSON.stringify(item, false, 2)}</pre> */}
    {isHighlightMediaItem ? (
      <HighlightMediaItem item={item} />
    ) : (
      <ListViewMediaItem
        item={item.data}
        uid={item.uid}
        mediaFilter={mediaFilter}
        topicFilter={topicFilter}
      />
    )}
  </>
)

MediaLibraryItem.propTypes = {
  item: object,
  isHighlightMediaItem: bool,
  mediaFilter: string,
  topicFilter: string,
}

export default MediaLibraryItem
