import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import * as Styled from './styles/PodcastDirectoryBlock.styles'

const PODCAST_DIRECTORY_QUERY = graphql`
  {
    allPrismicPodcastDirectory {
      edges {
        node {
          id
          data {
            directory_name
            list_order_number
            icon {
              url
              alt
              thumbnails {
                over {
                  url
                }
              }
            }
            link {
              id
              uid
              type
              link_type
              url
            }
          }
        }
      }
    }
  }
`

const PodcastDirectoryBlock = ({ sponsorshipText }) => (
  <StaticQuery
    query={`${PODCAST_DIRECTORY_QUERY}`}
    render={data => {
      const podcastDirectories = data.allPrismicPodcastDirectory.edges
      const podcastDirectoriesSorted = podcastDirectories.sort((a, b) =>
        a.node.data.list_order_number > b.node.data.list_order_number ? 1 : -1
      )
      return (
        <Styled.PodcastSidebar>
          <Styled.PodcastSidebarGrid>
            <Styled.TextContainer>
              <Styled.Title>Subscribe to our Podcast</Styled.Title>
              <Styled.List>
                {podcastDirectoriesSorted.map(podcastDirectory => (
                  <Styled.Item key={podcastDirectory.node.id}>
                    <Styled.IconLink
                      href={podcastDirectory.node.data.link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Styled.ItemIconContainer>
                        <Styled.IconImage
                          src={podcastDirectory.node.data.icon.url}
                          alt={podcastDirectory.node.data.directory_name}
                        />
                        <Styled.IconHoverImage
                          src={
                            podcastDirectory.node.data.icon.thumbnails.over.url
                          }
                          alt={podcastDirectory.node.data.directory_name}
                        />
                      </Styled.ItemIconContainer>
                      <Styled.ItemTextContainer>
                        <Styled.ItemTitle>
                          {podcastDirectory.node.data.directory_name}
                        </Styled.ItemTitle>
                      </Styled.ItemTextContainer>
                    </Styled.IconLink>
                  </Styled.Item>
                ))}
              </Styled.List>
              {sponsorshipText?.richText && (
                <Styled.SponsorshipText>
                  <PrismicRichText field={sponsorshipText.richText} />
                </Styled.SponsorshipText>
              )}
            </Styled.TextContainer>
            <Styled.CircleAccentTop>
              <svg
                width="159"
                height="159"
                viewBox="0 0 159 159"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="79.5"
                  cy="79.5"
                  r="76.5"
                  stroke="#829FE8"
                  strokeWidth="6"
                />
              </svg>
            </Styled.CircleAccentTop>
            <Styled.CircleAccentBottom>
              <svg
                width="116"
                height="116"
                viewBox="0 0 116 116"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="58"
                  cy="58"
                  r="55"
                  stroke="#F29F05"
                  strokeWidth="6"
                />
              </svg>
            </Styled.CircleAccentBottom>
          </Styled.PodcastSidebarGrid>
        </Styled.PodcastSidebar>
      )
    }}
  />
)

export default PodcastDirectoryBlock
