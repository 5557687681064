import styled from 'styled-components'
import { DEVICE } from '../../../util/constants'
import { Link } from 'gatsby'

export const CtaContainer = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  pointer-events: none;
  padding: 14px 0;
  text-decoration-line: none;

  svg {
    min-width: 30px;

    @media ${DEVICE.tabletL} {
      min-width: 45px;
    }
  }

  svg .rollover {
    display: none;
  }

  :hover {
    svg .rollover {
      display: inherit;
    }

    svg .normal {
      display: none;
    }
  }

  /* stylelint-disable value-keyword-case */
  ${({ $additionalStyles }) => $additionalStyles}
`

export const TextLink = styled.span`
  pointer-events: auto;
  font-size: 1.4rem;
  line-height: 1.4;
  font-weight: bold;
  letter-spacing: 0.02em;
  color: var(--highlight-blue);
  user-select: none;
  padding: 15px 14px 15px 0;
`
