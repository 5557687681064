export const formatTimeAmPm = date => {
  let hours = date.getHours()
  let minutes = date.getMinutes()
  const ampm = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? `0${minutes}` : minutes
  const strTime = `${hours}:${minutes}${ampm}`
  return strTime
}
const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
const SHORT_DAYS_OF_WEEK = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

// dates come in as strings : '2020-08-18'
export const formatDate = dateString => {
  if (!dateString) {
    return null
  }
  const splitter = dateString.split('-')
  const month = MONTHS[Number(splitter[1]) - 1]
  const day = splitter[2]
  const year = splitter[0]
  return `${month} ${day}, ${year}`
}

export const getShortDayOfWeek = dateString => {
  if (!dateString) {
    return null
  }
  const dateObj = new Date(dateString)
  const userTzOffset = dateObj.getTimezoneOffset() * 60000
  const correctedDate = new Date(dateObj.getTime() + userTzOffset)
  return SHORT_DAYS_OF_WEEK[correctedDate.getDay()]
}

export const formatShortDate = dateString => {
  if (!dateString) {
    return null
  }
  const splitter = dateString.split('-')
  const month = splitter[1]
  const day = splitter[2]
  return `${month}/${day}`
}

export const formatDuration = (hours, minutes, seconds) => {
  if (!hours && !minutes && !seconds) return ''
  const hoursString = hours > 0 ? `${hours}H` : ''
  const minutesString = minutes > 0 ? `${minutes}M` : ''
  const secondsString = `${seconds}S`
  const durationString = `PT${hoursString}${minutesString}${secondsString}`
  return durationString
}
/*
const SHORT_DAYS_OF_WEEK = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
const dateString = '2020-08-18'
const dateObj = new Date(dateString)
const userTzOffset = dateObj.getTimezoneOffset() * 60000
const correctedDate = new Date(dateObj.getTime() + userTzOffset)
*/

export const getIsDuringBusinessHours = () => {
  const businessHours = {
    OPEN: '07:30:00',
    CLOSE: '16:30:00',
  }

  const formattedDate = new Date().toLocaleString('en-US', {
    timeZone: 'America/Los_Angeles',
  })
  const now = new Date(formattedDate)

  const day = now.getDay()
  const time = now.toLocaleTimeString('en-US', { hour12: false })

  const isSaturdayOrSunday = day === 6 || day === 0
  const isBeforeOpeningOrAfterClosing =
    time < businessHours.OPEN || time > businessHours.CLOSE

  if (isSaturdayOrSunday || isBeforeOpeningOrAfterClosing) {
    return false
  }

  return true
}

export const getIsOfflineDate = offlineDates => {
  const formattedTodayDate = new Date().toLocaleDateString('en-US', {
    timeZone: 'America/Los_Angeles',
  })

  const isOfflineDate = offlineDates.find(date => {
    const dateParts = date.split('-').map(part => {
      if (part.charAt(0) === '0') {
        return part.substring(1)
      }

      return part
    })

    const dateString = `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`

    return dateString === formattedTodayDate
  })

  return !!isOfflineDate
}

export const futureEvents = events => {
  const now = new Date()
  const today = new Date(
    Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())
  )
  return events.filter(e => {
    const event = e.node?.data ? e.node.data : e.document.data
    const eventDate = new Date(event.event_date)
    return eventDate >= today
  })
}
