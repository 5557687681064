import styled from 'styled-components'

import GoogleAdBlock from '../ContentBlocks/GoogleAdBlock'

export const TopLineGoogleAd = styled(GoogleAdBlock)`
  padding-top: 20px;
`

export const BottomLineGoogleAd = styled(GoogleAdBlock)`
  margin-top: 40px;
`

export const PageSliceGoogleAd = styled(GoogleAdBlock)`
  margin-bottom: 64px;
`
