import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'

import { linkResolver } from '../../util/linkResolver'

const LinkWrapper = ({ link, children }) => {
  const [isValidLink, setIsValidLink] = useState(null)

  useEffect(() => {
    setIsValidLink(link?.url || link?.uid || link?.type || link?.id)
  }, [link])

  return isValidLink ? (
    <Link to={linkResolver(link)}>{children}</Link>
  ) : (
    <>{children}</>
  )
}

export default LinkWrapper
