import React from 'react'
import { WebinarTagIcon, VideoTagIcon, PodcastTagIcon } from '../Icons'
import { formatDate } from '../../util/timeDateUtils'

export const MediaIcon = ({ type }) => {
  switch (type) {
    case 'Podcast':
      return <PodcastTagIcon />
    case 'Webinar':
      return <WebinarTagIcon />
    case 'Video':
      return <VideoTagIcon />
    default:
      return null
  }
}

export const getMetadataDateByType = (item) => {
  const body = item.body?.slice(0, 1).pop()
  if (!body) { return null }
  switch (body.type) {
    case 'podcast':
      return formatDate(body.primary.podcast_episode_date)
    case 'video_block':
      return formatDate(body.primary.schema_publish_date)
    case 'webinar':
      // need to fix this
      return ''
    default:
      return ''
  }
}

export const getCtaLinkTextByType = (type) => {
  switch (type) {
    case 'Podcast':
      return 'Listen'
    case 'Video':
      return 'Watch'
    case 'Webinar':
      return 'Learn'
    default:
      return 'Learn'
  }
}