import React from 'react'

import { RegionContainer } from '../components/styles/Utility.styles'
import * as Styled from './styles/FilterRow.styles'

const FilterRow = ({ sectionTitle = 'Filter', children }) =>
  <RegionContainer>
    <Styled.FilterTitle>
      {sectionTitle}
    </Styled.FilterTitle>
    <Styled.FilterDropdownRow>
      {children}
    </Styled.FilterDropdownRow>
  </RegionContainer>

export default FilterRow