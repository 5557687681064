import styled from 'styled-components'
import { DEVICE } from '../../../util/constants'
import { GriddedContentBlock } from '../../styles/Utility.styles'

export const PodcastSidebar = styled(GriddedContentBlock)`
  /* Naming Convenience */
  margin-top: 70px;
  margin-bottom: 0;

  @media ${DEVICE.laptopG} {
    margin-top: 0;
    margin-bottom: 60px;
  }
`

export const PodcastSidebarGrid = styled.div`
  grid-column: 2 / 12;
  position: relative;

  [data-layout='two-column'] & {
    @media ${DEVICE.laptopG} {
      grid-column: span 12;
    }
  }
`

export const TextContainer = styled.div`
  background-color: var(--content-background-light);
  border-radius: 5px;
  padding: 25px 35px 28px 25px;
  position: relative;
  z-index: 2;

  @media ${DEVICE.laptopS} {
    padding-bottom: 43px;
  }
`

export const Title = styled.h3`
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 2.285em;
  text-transform: uppercase;
  color: var(--highlight-blue);
  margin-bottom: 10px;
`

export const Body = styled.div`
  font-size: 1.6rem;
  line-height: 1.375em;
  color: var(--content-background-dark-blue);

  @media ${DEVICE.laptopS} {
    margin-top: 0;
    font-size: 1.8rem;
    line-height: 1.6em;
  }

  p {
    margin-bottom: 1.45em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: var(--content-background-dark-blue);
    text-decoration: underline;

    &:hover,
    &:active {
      color: var(--highlight-blue);
    }
  }
`

export const List = styled.ul`
  list-style: none;
  font-size: 1.4rem;
  line-height: 1.9rem;
  color: var(--content-background-dark-blue);
  margin-top: 20px;

  @media ${DEVICE.laptopS} {
    margin-top: 30px;
  }
`

export const Item = styled.li`
  display: flex;
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
  }

  @media ${DEVICE.laptopS} {
    margin-bottom: 38px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const SponsorshipText = styled.div`
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.63;
  color: var(--highlight-blue);
  margin-top: 30px;

  @media ${DEVICE.laptopS} {
    margin-top: 50px;
  }
`

export const IconLink = styled.a`
  display: flex;
  color: var(--content-background-dark-blue);
  text-decoration: none;

  &:hover,
  &:active {
    color: var(--highlight-blue);
  }
`

export const ItemIconContainer = styled.div`
  display: flex;
  justify-content: center;
  flex: 0 0 37px;
  margin-right: 36px;

  svg {
    margin-top: 3px;

    @media ${DEVICE.laptopS} {
      margin-top: 4px;
    }
  }
`

export const IconImage = styled.img`
  display: block;

  ${IconLink}:hover & {
    display: none;
  }
`

export const IconHoverImage = styled.img`
  display: none;

  ${IconLink}:hover & {
    display: block;
  }
`

export const ItemTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const ItemTitle = styled.span`
  font-size: 1.6rem;
  line-height: 1.375em;
  margin-top: 16px;

  &:first-of-type {
    margin-top: 0;
  }

  @media ${DEVICE.laptopS} {
    margin-top: 0;
    font-size: 1.8rem;
    line-height: 1.6em;
  }
`

export const CircleAccentTop = styled.div`
  position: absolute;
  width: 129px;
  height: 129px;
  top: -47px;
  right: -13px;

  svg {
    width: 100%;
    height: auto;
  }

  @media ${DEVICE.laptopS} {
    width: 159px;
    height: 159px;
    top: -54px;
    right: -20px;
  }
`

export const CircleAccentBottom = styled.div`
  position: absolute;
  width: 86px;
  height: 86px;
  bottom: -28px;
  left: 32px;

  svg {
    width: 100%;
    height: auto;
  }

  @media ${DEVICE.laptopS} {
    width: 116px;
    height: 116px;
    left: 53px;
    bottom: -21px;
  }
`
